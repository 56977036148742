/*
https://ui.docs.amplify.aws/react/theming/style-props
*/
.placeholder-local-styles {
  --amplify-components-placeholder-start-color: white;
  --amplify-components-placeholder-end-color: white;
}

.imprint-button {
  position: relative;
  background-color: #f9f9f9;
  --amplify-components-button-border-radius: 20px;
  --amplify-components-button-hover-background-color: #ffa500;
  --amplify-components-button-hover-border-color: #ffa500;
  --amplify-components-button-font-size: 5px;
  --amplify-components-button-font-weight: 20px;
  --amplify-components-button-border-color: #ffa500;
  --amplify-components-button-hover-border-color: #ffa500;
  --amplify-components-button-focus-color: black;
  --amplify-components-button-focus-box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.1);
  --amplify-components-button-active-border-color: #ffa500;
  --amplify-components-button-active-background-color: #ffa500;
  --amplify-components-button-active-color: black;
  --amplify-components-button-hover-color: black;
  --amplify-components-button-focus-border-color: #ffa500;
}

.contact-button {
  position: relative;
  background-color: #f9f9f9;
  --amplify-components-button-border-radius: 20px;
  --amplify-components-button-hover-background-color: #ffa500;
  --amplify-components-button-hover-border-color: #ffa500;
  --amplify-components-button-font-size: 5px;
  --amplify-components-button-font-weight: 20px;
  --amplify-components-button-border-color: #ffa500;
  --amplify-components-button-active-border-color: #565656;
  --amplify-components-button-focus-box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.1);
}

.admin-button {
  position: relative;
  color: white;
  background-color: rgb(175, 31, 31);
  --amplify-components-button-border-radius: 20px;
  --amplify-components-button-hover-background-color: #565656;
  --amplify-components-button-hover-border-color: #565656;
  --amplify-components-button-hover-color: white;
  --amplify-components-button-font-size: 5px;
  --amplify-components-button-font-weight: 20px;
  --amplify-components-button-border-color: #565656;
  --amplify-components-button-active-border-color: #565656;
  --amplify-components-button-focus-box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.1);
}

.signIn-button {
  background-color: #ffa500;
  --amplify-components-button-color: #565656;
  --amplify-components-button-hover-background-color: #565656;
  --amplify-components-button-hover-border-color: black;
  --amplify-components-button-border-color: #565656;
  --amplify-components-button-hover-color: #ffa500;
  --amplify-components-button-hover-border-color: #ffa500;
  --amplify-components-button-focus-color: #ffa500;
  --amplify-components-button-focus-border-color: #ffa500;
  --amplify-components-button-foucs-background-color: #565656;
  --amplify-components-button-border-width: 2px;
  --amplify-components-button-active-color: #565656;
  --amplify-components-button-padding-block-end: 10px;
  --amplify-components-button-padding-inline-end: 10px;
  --amplify-components-button-padding-block-start: 10px;
  --amplify-components-button-padding-inline-start: 10px;
}
/*
  .signIn-button:focus {
    --amplify-components-button-focus-box-shadow: 0px 2px 4px #565656;
  }
  */
.commit-button {
  display: flex;
  position: relative;
  background-color: #565656;
  font-family: Arial;
  color: #ffa500;
  --amplify-components-button-color: #ffa500;
  --amplify-components-button-hover-background-color: #ffa500;
  --amplify-components-button-hover-border-color: #ffa500;
  --amplify-components-button-border-color: #ffa500;
  --amplify-components-button-hover-color: #565656;
  --amplify-components-button-hover-border-color: #565656;
  --amplify-components-button-focus-color: #565656;
  --amplify-components-button-focus-border-color: #565656;
  --amplify-components-button-foucs-background-color: #ffa500;
  --amplify-components-button-border-width: 3px;
  --amplify-components-button-border-radius: 20px;
  --amplify-components-button-active-color: #ffa500;
  --amplify-components-button-active-background-color: #ffa500;
  --amplify-components-button-primary-active-color: #ffa500;
  --amplify-components-button-active-border-color: #565656;
  --amplify-components-button-primary-border-color: #ffa500;
  --amplify-components-button-primary-active-border-color: #ffa500;
  --amplify-components-button-focus-box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.1);
  --amplify-components-button-small-padding-block-end: 10px;
  --amplify-components-button-small-padding-inline-end: 10px;
  --amplify-components-button-small-padding-block-start: 10px;
  --amplify-components-button-small-padding-inline-start: 10px;
  --amplify-components-button-font-size: 15px;
}

.info-alert {
  position: relative;
  border-radius: 7px;
  --amplify-components-alert-info-background-color: #f2f3f3;
  --amplify-components-alert-info-color: black;
  opacity: 1;
}

.language-button {
  --amplify-components-button-border-color: #565656;
  --amplify-components-button-active-border-color: #565656;
  --amplify-components-button-focus-border-color: #565656;
  --amplify-components-button-hover-border-color: #ffa500;
}

.language-divider {
  --amplify-components-divider-border-color: #565656;
  --amplify-components-divider-border-width: 3px;
}

.language-divider-underlay {
  --amplify-components-divider-border-color: #f2f3f3;
  --amplify-components-divider-border-width: 7px;
}

.text-element {
  font-family: Arial;
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  text-align: left;
  white-space: pre-line;
  color: black;
  position: relative;
  padding-block-start: 25px;
}

.text-element-header {
  font-family: Arial;
  font-size: 23px;
  font-weight: 400;
  text-align: center;
  white-space: pre-line;
  color: black;
  position: relative;
  padding-block-start: 30px;
}

.text-element-admin {
  font-family: Arial;
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  text-align: left;
  white-space: pre-line;
  color: black;
  position: relative;
}

.select-field {
  position: relative;
}

.password-field {
  position: relative;
  padding-block-start: 4px;
  --amplify-components-passwordfield-color: green;
}

.text-field {
  position: relative;
  padding-block-start: 4px;
}

.tire-button {
  display: flex;
  position: relative;
  width: 55px;
  height: 25px;
  transform-origin: top left;
  transform: rotate(0.75turn);
  opacity: 0.7;
  border-radius: 21px;
  border: 1px solid rgba(13, 26, 38, 1);
  --amplify-components-button-hover-border-color: #565656;
}

.language-button {
  position: relative;
  height: 38px;
  width: 64px;
  border-radius: 1px;
  background-color: transparent;
  opacity: 0;
}

.text-field-DOT {
  display: flex;
  position: relative;
  direction: column;
  width: 34px;
  padding-block-start: 4px;
}

.text-element-comma {
  font-size: 35px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  white-space: pre-line;
  color: black;
  position: relative;
  left: -13px;
  top: 40px;
}

.text-field-tread-depth {
  width: 45px;
  text-align: center;
  display: flex;
  position: relative;
  flex-direction: column;
}

tr {
  border-top: 1px solid #c6cbd1;
  background: #fff;
}

th,
td {
  padding: 6px 13px;
  border: 1px solid #dfe2e5;
}

table tr:nth-child(2n) {
  background: #f6f8fa;
}
